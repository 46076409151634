














import Vue from 'vue'
import { mapState } from 'vuex'

import Header from '@/components/organisms/c/Header.vue'
import HSideBar from '@/components/organisms/h/HSideBar.vue'
export default Vue.extend({
  components: { Header, HSideBar },
  computed: {
    ...mapState('app', ['belongStores']),
  },
  methods: {
    onSelectStoreCode(storeCode: string) {
      this.$store.dispatch('app/updateSelectedStoreCode', {
        storeCode: storeCode,
      })
    },
  },
})
