



























































































import { BIconGear, BIconDoorClosed } from 'bootstrap-vue'
import dayjs from 'dayjs'
import Vue, { PropType } from 'vue'
import { mapState, mapGetters } from 'vuex'

import Breadcrumb from './Breadcrumb.vue'

import { AvailableStore } from '@/types/entities'
import config from '@/utils/config'
import { pathGroup } from '@/utils/constant'
import { today } from '@/utils/mixin'
import Toast from '@/utils/toast'

/**
 * アプリケーションヘッダ
 *
 * 店舗は背景青、本部は背景黒
 *
 * サービス名は config で変更可能
 */
export default Vue.extend({
  components: { BIconGear, BIconDoorClosed, Breadcrumb },
  props: {
    storeList: {
      type: Array as PropType<AvailableStore[]>,
      default: () => [],
    },
  },
  computed: {
    ...mapState('app', ['selectedStoreCode', 'userInfo']),
    ...mapGetters('app', [
      'isBelongStoresLoading',
      'getCategoryInCharge',
      'isExtServiceWarn',
    ]),
    isDashboard() {
      return this.$route.name === 's'
    },
    loginUserName() {
      const u = this.userInfo
      if (!u) return '-'
      return u.displayName ? u.displayName : u.loginId
    },
    emptyStoreOption() {
      return [{ text: this.$t('label.noBelongingStore'), value: '' }]
    },
    isShop() {
      return ['/s', '/x'].includes(this.$route.fullPath.slice(0, 2))
    },
    categoryInCharge() {
      // 両方あるときだけ”：”を表示する
      if (!this.getCategoryInCharge) {
        return '-'
      } else {
        const separator =
          this.getCategoryInCharge.assignedGenreCategoryCd &&
          this.getCategoryInCharge.assignedGenreCategoryName
            ? ':'
            : ''
        return `${
          this.getCategoryInCharge.assignedGenreCategoryCd || ''
        }${separator}${
          this.getCategoryInCharge.assignedGenreCategoryName || ''
        }`
      }
    },
    dispStoreList() {
      return this.storeList.map((s: AvailableStore) => ({
        storeCd: s.storeCd,
        storeName: `${s.storeCd}:${s.storeName}`,
      }))
    },
    dispStore() {
      const store = this.storeList.find(
        (s: AvailableStore) => s.storeCd === this.select
      )
      return store ? `${store.storeCd}:${store.storeName}` : '-'
    },
    serviceName() {
      return config.serviceName
    },
    targetToday() {
      return dayjs(today()).format('YYYY-MM-DD')
    },
    appTheme() {
      const key = pathGroup[this.$route.fullPath.slice(1, 2)]
      const bgColor = key ? key.theme : pathGroup['s'].theme
      return `background-color:${bgColor}`
    },
    buildInfo() {
      // 環境変数が定義されていたらbuild情報を出力する
      if (process.env.VUE_APP_REAL_UI_BUILD) {
        return `build ${process.env.VUE_APP_REAL_UI_BUILD}`
      }
      return ''
    },
    envInfo() {
      // 環境変数が定義されていたらbuild情報を出力する
      console.log(`process.env.VUE_APP_ENV: ${process.env.VUE_APP_ENV}`)
      const env = process.env.VUE_APP_ENV
      if (env != 'prd') {
        return `評価環境（${env})`
      }
      return ''
    },
  },
  async mounted() {
    this.select = this.selectedStoreCode
    this.timer()
  },
  data() {
    return {
      select: '',
      logo: config.serviceLogo,
      serviceTopUrl: config.serviceUrl,
      nowTime: null,
    }
  },
  methods: {
    timer() {
      this.nowTime = `${dayjs(today()).format('MM/DD')} ${dayjs().format(
        'HH:mm'
      )}`
      setTimeout(this.timer, 60)
    },
    async onLogout() {
      try {
        await this.$store.dispatch('auth/logout')
        const currentPath = this.$route.path
        const base = `/${currentPath.split('/')[1]}`
        if (base !== currentPath) {
          // avoid redundant vavigation
          if (base === '/x') {
            this.$router.push('/s')
          } else {
            this.$router.push(base)
          }
        }
      } catch (e) {
        Toast.error(this, { message: '' + e })
      }
    },
    onPersonalSetting() {
      this.$router.push('/c/settings')
    },
  },
  watch: {
    select() {
      console.log(`select ${this.select}`)
      /**
       * 店舗コードの選択を変えた場合に発火。
       */
      this.$emit('onSelectStoreCode', this.select)
    },
    selectedStoreCode() {
      this.select = this.selectedStoreCode
    },
  },
})
