const defaultConfig = {
  /**
   * 画面左上のシステム名
   */
  serviceName: '自動発注システム　Real',
  /**
   * 画面左上のシステム名クリック時の遷移先
   */
  serviceUrl: 'https://www.optarc.net/',
  /**
   * 画面左上のロゴ
   */
  serviceLogo: '',
  /**
   * サポートURL
   */
  supportML: '',
  /**
   * 通常発注の表示カスタマイズ
   */
  displayAttributeSetting: {
    fixed: [
      'storageDays/orderUnit', // 管理日数／発注単位
      'weeklySales', // 週販推移
      'deadline', // 締切時刻
      'lastOrderedDate', // 最終発注日
    ],
  },
  userSlip: false,
}

// function loadCustomConfig() {
//   let _config = defaultConfig
//   try {
//     const _configContext = require.context('./x', true, /config.ts/i)
//     _configContext.keys().forEach((key) => {
//       _config = Object.assign(defaultConfig, _configContext(key).default)
//     })
//   } catch (err) {
//     // do nothing (use default)
//   }
//   return _config
// }
// const config = loadCustomConfig()
const config = defaultConfig
console.log(config)
export { config as default }
