import dayjs from 'dayjs'

import store from '@/store'

function currencyFormatted(n: number) {
  if (n == null || n == undefined) return '-'
  if (n === 0) return '0'
  return (
    n &&
    n.toLocaleString('ja-JP', {
      style: 'decimal',
      currency: 'JPY',
    })
  )
}

/**
 * メンテナンス文言１を返す
 * @returns
 */
function displayMaintenanceWord1() {
  if (store && store.state && store.state.app) {
    return store.getters['app/displayMaintenanceWord1']
  } else {
    return ''
  }
}

/**
 * メンテナンス文言１を返す
 * @returns
 */
function displayMaintenanceWord2() {
  if (store && store.state && store.state.app) {
    return store.getters['app/displayMaintenanceWord2']
  } else {
    return ''
  }
}

/**
 * システム上の今日を返す
 * @returns
 */
function today() {
  if (store && store.state && store.state.app) {
    return new Date(store.getters['app/baseDate'])
  } else {
    return new Date()
  }
}

export const formatMixIn = {
  methods: {
    currencyFormatted,
    today,
  },
}

export const commonMixIn = {
  methods: {},
}

export {
  currencyFormatted,
  today,
  displayMaintenanceWord1,
  displayMaintenanceWord2,
}

function todayYMD() {
  return dayjs(today()).format('YYYY-MM-DD')
}

export function addDayYMD(days: number) {
  const day = dayjs(today())
  const addDay = day.add(days, 'd')
  return addDay.format('YYYY-MM-DD')
}
