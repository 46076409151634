




import {
  ref,
  defineComponent,
  onMounted,
  watch,
  getCurrentInstance,
} from '@vue/composition-api'

import { PathGroup, pathGroup } from '@/utils/constant'

export default defineComponent({
  setup(props, context) {
    const instance = getCurrentInstance()
    if (!instance) {
      return
    }
    const items = ref<{ text: string; to: string; active: boolean }[]>([])

    const updateItems = () => {
      // /x/store/:id/slip/regist 3
      // /s/store/:id/order 3
      // /h/setting-shelfconf 1
      const { $route, $i18n } = instance.proxy

      const key = $route.fullPath.slice(1, 2) || 's'
      const pg = pathGroup[key as keyof PathGroup]
      const fullPath = $route.fullPath.split('/')
      const splitNum = pg.splitNum
      const cPath = $route.matched[$route.matched.length - 1].path
      const paths = cPath.split('/').slice(splitNum)
      const _r = [{ text: 'ホーム', to: pg.home, active: false }]
      let text = ''
      paths.map((i, index) => {
        if (i.startsWith(':')) return
        text = text === '' ? i : text + ':' + i
        console.log(`fullPath: ${fullPath}`)
        let to = fullPath.slice(0, index + splitNum + 1).join('/')
        let active = true //root以下のリンクを無効化
        // @ts-ignore
        _r.push({ text: $i18n.t('path.' + text), to, active })
      })
      items.value = _r
    }

    onMounted(() => {
      updateItems()
    })
    watch(
      () => instance.proxy.$route,
      () => {
        updateItems()
      }
    )

    return {
      items,
    }
  },
})
